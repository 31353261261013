import { Controller } from "@hotwired/stimulus"
import Select2 from "~/scripts/lib/Select2";
export default class extends Controller {
  connect() {
    this.bindSectionPagesSelect();
    this.bindSelect2Events();
    this.bindTooltipForSectionPagesOption();
    this.autoSaver = new window.R.AutoSaver();
  }

  bindSectionPagesSelect() {
    if ($('#limited_admin_modules-table').length > 0){
      const sectionPagesSelector = ".limited_admin_section_pages";
      const addSectionSelector = ".add_limited_admin_section";
      const that = this;
      if (!$.fn.DataTable.isDataTable("#limited_admin_modules-table")) {
        this.$table = $("#limited_admin_modules-table").DataTable({
          responsive: true,
          processing: true
        });
      }
      else {
        this.$table = $("#limited_admin_modules-table").DataTable();
      }

      this.$table.on('draw.dt', function () {
        this.select2 = new Select2(function () {
          $(addSectionSelector).select2();
          $(sectionPagesSelector).select2();
        });
        that.autoSaver.autoSave(sectionPagesSelector);
      });
    }
  }

  bindSelect2Events() {
    $(document).on("select2:select", ".limited_admin_section_pages", function (e) {
      var recently_selected_option = e.params.data.text;
      var section_id = this.id
      if(this.value === "All"){
        $('#' + section_id).val([recently_selected_option]).trigger('change');
      }
    });
  }
  bindTooltipForSectionPagesOption(){
    $(document).on('select2:open', function() {
      $(".select2-results__option[aria-disabled='true']")
        .attr('title', 'Upgrade your plan to get this exclusive feature to assign specific section pages.')
        .tooltip({ placement: 'top' });
    });
  }
  
}