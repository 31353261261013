import { Controller } from "@hotwired/stimulus"
import Select2 from "~/scripts/lib/Select2";
export default class extends Controller {
  connect() {
    this.bindRoleSelect();
    this.bindRoleChangeEvent();
  }

  bindRoleSelect() {
    if ($('#company_role_id').length > 0){
      const roleSelector = "#company_role_id";
      new Select2(function () {
        $(roleSelector).select2();
      });
    }
  }

  bindRoleChangeEvent() {
    $(document).on("change", "#company_role_id", function () {
      $.ajax({
        method: "GET",
        url: "allocated_role",
        data: {
          company_role_id: this.value
        },
        success: function(data){
          if(data.data != null)
            $(".add-company-role").prop("disabled", true);
          else
            $(".add-company-role").prop("disabled", false);
        }
      })
    });
  }
}